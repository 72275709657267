<template>
  <div id="customersTargeted">
    <apexchart
      ref="scChart"
      :options="customOptions"
      :series="chartSeries"
      :chartRefresh="chartRefresh"
      :height="height"
    >
    </apexchart>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 capitalize">
          <span class="font-weight-bold"> {{ pointTitle }}</span>
        </v-card-title>

        <v-card-text class="text-h6 py-10">
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn @click="next(pointTitle,'Activated in CRM','crm')" outlined  color="#109cf1 ">
            Activate in CRM
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="next(pointTitle,'Activated in ShareThis','sharethis')" outlined  color="#94d227">
            Activate in ShareThis
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["series", "options", "height", "chartRefresh"],
  data() {
    return {
      pointTitle: "",
      pointDesc: "",
      dialog: false,
      chartSeries: [],
      chartOptions: [],
    };
  },
  mounted() {
      this.chartSeries = this.series;
       console.log("series", this.series);
       console.log("options", this.options);
  },
  computed: {
    customOptions: function () {
      return {
        ...this.options,
        dataLabels: {
          ...this.options.dataLabels,
          formatter: function (val, opt) {
            console.log(val, opt, opt.w);
            return opt.w.globals.seriesNames[opt.seriesIndex];
          },
        },
        chart: {
          ...this.options.chart,
          events: {
            dataPointSelection: (e, chart, opts) => {
              this.showInfo(e, chart, opts);
            },
          }
        }
      };
    },
  },
  methods: {
    next(pointTitle,activated__in,approach) {
      this.$store.commit("setCategoryEvent", pointTitle);
      this.$store.commit("setStepper", { type: "crm", val: 4 });
      this.$store.commit("setActivatedIn", { title: activated__in, approach:approach });
      this.$router.push({ path: "/data-overview" });
    },
    showInfo(e, chart, opts) {
      this.pointTitle = opts.w.globals.seriesNames[opts.seriesIndex];
      this.pointDesc = opts.w.config.series[opts.seriesIndex].data[0];
      this.pointDesc = opts.w.config.series[opts.seriesIndex].data[0];
      this.dialog = true;
      console.log(e, chart, opts);
    },
  }, 
  watch: {
    chartRefresh: function () {
      console.log("chartRefresh");
      this.$refs.scChart.updateOptions({ labels: this.customOptions });
      this.$refs.scChart.updateSeries({ data: this.chartSeries });
    },
  },
};
</script>
