<template>
  <div id="event-insights">
    <v-row>
      <v-col cols="12" sm="12" class="pa-1">
        <div class="display-1">
          Category Insight Index 
          <v-btn
            @click="next()"
            class="float-right btns color-white"
            color="#94d227"
            elevation="0"
            >Next</v-btn>
            <v-btn
            @click="$router.go(-1)"
            class="float-right btns color-white mr-4"
            color="#94d227"
            elevation="0"
            >Back</v-btn>
            <v-menu
            v-model="filter"
            :close-on-content-click="true"
            offset-y
            class="float-right"
            bottom
            origin="center center"
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                elevation="0"
                :color="getTierColor"
                class="
                  float-right
                  mr-8
                  btns
                  color-white
                  black--text
                  text-capitalize
                  shadow-none
                "
                v-bind="attrs"
                v-on="on"
              >
                <v-icon dark>mdi-filter-outline</v-icon>
                {{ tier ? tier : "Filter" }}
              </v-btn>
            </template>

            <v-card>
              <v-list class="pa-0">
                <v-radio-group
                  v-model="tier"
                  :mandatory="false"
                  class="ma-0 pa-0"
                  hide-details=""
                >
                  <v-list-item
                    class="h-12"
                    v-for="(item, index) in tiers"
                    :key="index"
                  >
                    <v-list-item-action>
                      <v-radio
                        :color="item.color"
                        @click="Filter(item.title)"
                        :value="item.title"
                      ></v-radio>
                    </v-list-item-action>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-radio-group>
              </v-list>
            </v-card>
          </v-menu>
        </div>
      </v-col>
    </v-row>
    <v-row class="my-10">
      <v-col cols="12" sm="12" class="py-8 pa-0">
        <v-card class="mx-auto pa-10">
          <div
            class="
              display-6
              font-weight-bold
              my-1
              flex
              justify-between
              items-center
            "
          >
            Index
          </div>
          <CustomersScatterChart
            v-if="show"
            height="500"
            :series="scatter.series"
            :options="scatter.options"
            :chartRefresh="chartRefresh"
          />
          <div
            class="
              display-6
              font-weight-bold
              my-1
              text-center
            "
          >
            Count
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script> 
import CustomersScatterChart from "./Charts/CustomersTargeted.vue";
export default {
  components: { CustomersScatterChart },
  data() {
    return {
      show:false,
      filter: false,
      tier: this.$store.getters.getSegBtn,
      tiers: [
        { color: "#87f8b4", title: "high" },
        { color: "#ffe380", title: "medium" },
        { color: "#f88787", title: "low" }
      ],
      scatter:{
        options:{},
        series:[]
      },
      maxTarget: 0,
      approchName: this.$store.getters.getApproch,
      tierName: this.$store.getters.getSegBtn,
      cityName: this.$store.getters.getCity,
      data: {
        labels: [],
        datasets: [
          {
            lineTension: 0,
            pointBorderColor: "#317fcc",
            pointBorderWidth: 2,
            pointHoverBackgroundColor: "#317fcc",
            pointHoverBorderColor: "#317fcc",
            pointHoverBorderWidth: 3,
            pointRadius: 2,
            pointHitRadius: 10,
            backgroundColor: "transparent",
            borderColor: "#317fcc",
            pointBackgroundColor: "",
            pointHoverRadius: 5,
            label: "This Month",
            spanGaps: true,
            data: [],
          },
        ],
      },
      chartRefresh: false,
    };
  },
  created() {
    // if (this.$store.getters.getStepper.crm < 3) {
    //   this.$router.go(-1);
    // }
  },
  mounted() {
    this.get_top_cats()
  },
  methods: {
     Filter(tier) {
      this.tierName=tier.toLowerCase()
      this.$store.commit("setSegBtn", this.tierName);
      this.get_top_cats();
      this.show=false
    },
    async get_top_cats() {
      await this.$http
        .post("/sharethis_index_scatter?clustering_technique="+this.approchName+"&tier="+this.tierName+"&city="+this.cityName)
        .then((res) => {
          console.log(
            "--sharethis_index_scatter",
            res.data
          );
          this.scatter.series=res.data.series
          this.scatter.options=res.data.options
          this.chartRefresh=!this.chartRefresh
          this.show=true
        })
        .catch((err) => {
          console.log(err);
        });
    },
    next() {
      this.$store.commit("setStepper", { type: "crm", val: 4 });
      this.$router.push({ path: "/data-overview" });
    },
  },
    computed: {
    getTierColor() {
      return this.tiers.find((tier) => tier.title == this.$store.getters.getSegBtn).color;
    }}
};
</script>
<style lang="scss" scoped>
#event-insights::v-deep {
  .card__slider {
    display: flex !important;
  }
  .v-slider__thumb-container:hover .v-slider__thumb:before {
    transform: scale(0) !important;
  }
  .v-slider__thumb-label * {
    transform: none !important;
  }
  .v-slider__thumb-label {
    border-radius: 5px !important;
    transform: none !important;
    left: -25px !important;
    height: 20px !important;
    top: 2em !important;
    width: 5em !important;
  }
  .v-slider__thumb-container > .v-slider__thumb {
    width: 24px !important;
    height: 24px !important;
  }
  .theme--light.v-slider .v-slider__thumb {
    border-color: #ffffff !important;
    border: 4px solid;
    box-shadow: 0px 0px 9px -1px #000000;
  }
  .v-slider__track-background.primary.lighten-3 {
    background: rgba(0, 0, 0, 0.26) !important;
  }
  .v-btn--disabled {
    background: #8ccff8 !important;
  }
}
</style>